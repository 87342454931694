<!-- 设置 账号管理 -->
<template>
  <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="30%"
  >
    <el-form
        ref="form"
        :model="passwordObj"
        label-width="80px"
        label-position="left"
        size="small"
        :rules="rules"
        status-icon
    >
      <el-form-item label="密码" prop="pass">
        <el-input
            type="password"
            v-model="passwordObj.pass"
            autocomplete="off"
            show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
            type="password"
            v-model="passwordObj.checkPass"
            autocomplete="off"
            show-password
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="flex justify-end">
      <el-button @click="close">关闭</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.passwordObj.checkPass !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.passwordObj.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      passwordObj: {
        pass: '',
        checkPass: ''
      },
      rules: {
        pass: [
          {
            validator: validatePass,
            trigger: 'blur'
          }
        ],
        checkPass: [
          {
            validator: validatePass2,
            trigger: 'blur'
          }
        ]
      },
      id: 0
    }
  },

  methods: {
    close () {
      this.initData()
      this.dialogVisible = false
    },
    show (item) {
      return new Promise(resolve => {
        this.initData()
        this.id = item.id
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          resolve()
        })
      })
    },
    initData () {
      this.passwordObj = {
        pass: '',
        checkPass: ''
      }
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$api.sysUser.updateUserPassword({
            id: this.id,
            password: this.$rsa(this.passwordObj.checkPass)
          }).then(() => {
            this.close()
            this.$emit('on-submit')
          })
        }
      })
    }
  },
  mounted () {
  }
}
</script>
