<!-- 账号管理 -->
<template>
  <div>
    <blockTitle title="账号管理"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <AppTable
            ref="AppTable"
            controller="sysUser"
            :tableColumns="userManageColumns"
            :request-params="requestParams"
            @on-handleSelection-change="onHandleSelectionChange"
        >
          <template #quick-operator-box>
            <el-button type="primary" size="mini" @click="addUser"
            >创建用户
            </el-button>
            <template class="ml-4" v-if="multipleSelection.length > 0">
              <el-button type="danger" size="mini" @click="deleteSysUser"
              >删除用户
              </el-button>
            </template>
          </template>
          <!--          <template #left-operator>-->
          <!--            <div class="h-8 flex flex-row">-->
          <!--            </div>-->
          <!--          </template>-->
          <template #operator="{ row }">
            <el-dropdown size="small" split-button @click="editUser(row)">
              编辑
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div
                      class="w-24 h-10 flex align-items-center"
                      @click="editPsw(row)"
                  >
                    <i class="el-icon-edit pr-1.5 text-base"></i>
                    修改密码
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </AppTable>
      </div>
    </el-card>
    <editSysUserDialog
        ref="editSysUserDialog"
        @on-submit="reGetData"
    ></editSysUserDialog>
    <editPasswordDialog
        ref="editPasswordDialog"
        @on-submit="reGetData"
    ></editPasswordDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import editSysUserDialog from 'components/sysUser/editSysUserDialog'
import editPasswordDialog from 'components/sysUser/editPasswordDialog'

export default {
  components: {
    editSysUserDialog,
    editPasswordDialog
  },
  data () {
    return {
      userManageColumns: [
        {
          label: '#',
          type: 'selection'
        },
        {
          label: '账号',
          prop: 'account',
          search: true
        },
        {
          label: '角色',
          prop: 'sysRoleName'
        },
        {
          label: '创建时间',
          prop: 'createDtm'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      requestParams: {},
      multipleSelection: []
    }
  },
  methods: {
    onHandleSelectionChange (list) {
      this.multipleSelection = list
    },
    deleteSysUser () {
      const ids = this.multipleSelection.map(item => item.id)
      this.$api.deleteBatch('sysUser', ids).then(() => {
        this.reGetData()
      })
    },
    reGetData () {
      this.$refs.AppTable.clearSelection()
      this.$refs.AppTable.reGetData()
    },
    addUser () {
      this.$refs.editSysUserDialog.show()
    },
    editUser (item) {
      this.$refs.editSysUserDialog.edit(item)
    },
    editPsw (item) {
      this.$refs.editPasswordDialog.show(item)
    }
  },
  mounted () {
  }
}
</script>
